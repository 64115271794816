import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import ErrorPage from 'components/ErrorPage';

const FourOhFourPage = () => <ErrorPage statusCode={404} />;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default FourOhFourPage;
